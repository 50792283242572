.Footer{
    width: 100%;
}
.Footer-Section{
width: 100%;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #191919;
/* border-top: 5px solid orange; */
}

.Footer-Section .border{
    border-left: 100px solid #D87D4A;
    width: 100px;
    height: 4px;
    margin-bottom: 50px;
}
.Footer-Nav .Navbar{
    display: flex;
    flex-direction: column;
}
.Footer-Nav .Navbar .Wrapper{
    border: none;
    max-width: 1440px;
    display: flex;
}
.Footer-Nav .Navbar .Wrapper .Logo-Link{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.Footer-Description{
    max-width: 1100px;
    color: white;
    display: flex;   
}
.Footer-Description .Desc{
  flex: 1;
}
.Footer-Description .Desc p{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
}


.Footer-Description .Desc p a{
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 700;
    } 
    
.Footer-Description .Desc p:first-child{
    padding-bottom: 56px;
}
.Footer-Description .Desc p:nth-child(2){
    padding-bottom: 48px;
}
.Footer-Description .Social-Icon{
    flex: 1;
    position: relative;

}

.Footer-Description .Social-Icon .Social-Icons{
    position: absolute;
    right: 0;
    bottom: 125px;
}
.Footer-Description .Social-Icon img{
 
    padding-left: 25px;
    cursor: pointer;
    
}


@media screen and (max-width: 1100px){
    
    .Footer-Nav .Navbar .Wrapper{
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    padding-bottom: 0;
    }
    .Footer-Nav .Navbar .Wrapper .Logo-Link .PageLink{
        display: flex;
        padding-top: 32px;
    }

    .Footer-Section .border{
        margin-left: 50px;
        margin-bottom: 15px;
    }
    .Footer-Description{
        flex-direction: column;
    }
    .Footer-Description .Desc{
        padding-top: 25px;
        padding-left: 50px;
        padding-right: 50px;
        max-width: 1050px;
        
    }
    .Footer-Description .Desc p:first-child{
        padding-bottom: 50px;
    }
    .Footer-Description .Social-Icon{
        position: relative;
        /* background-color: red; */
     
    }
    .Footer-Description .Social-Icon .Social-Icons{
        position: absolute;
        bottom: 40px;
        right: 50px;
    }
}

@media screen and (max-width: 768px){
    .Footer-Nav .Navbar{
        display: flex;
        overflow: hidden;
    }
    .Footer-Nav .Navbar .Wrapper{
        display: flex;
        align-items: center;
        margin-left: 0;

    }
    .Footer-Nav .Navbar .Wrapper .Logo-Link .Logo{
        margin-right: 0;
    }
    .Footer-Nav .Navbar .Wrapper .PageLink{
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        gap: 0;
    }
    .Footer-Nav .Navbar .Wrapper .PageLink a{
        padding-bottom: 20px;
    }
    .Footer-Section .border{
        margin-left: 0px;
    }

    .Footer-Container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
 
    .Footer-Description .Desc{
        text-align: center;
    }

    .Footer-Description .Social-Icon .Social-Icons{
        position: relative;
        right:0;
        bottom: 0;
        display: flex; 
        justify-content: center;
padding-bottom: 50px;
    }
}