
.CartContents{
    width:375px;
    background-color:white;
    padding: 32px;
    border-radius : 10px;
    position : absolute;
    top:150px;
    right:120px;
    z-index: 9999999;
    /* top: 350px;
    left: 600px; */
    box-shadow: rgba(50, 50, 93, 0.50) 0px 13px 27px 5px, rgba(0, 0, 0, 0.50) 0px 8px 16px -8px;
 
   
    
}

.CardButton{
    display:flex;
    justify-content:space-between;
    padding-bottom: 32px;
}
.CardButton h4{ 
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1.25px;
}

.CardButton button{
border: none;
background-color: white;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 25px;
letter-spacing: 1.1px;
text-decoration-line: underline;
color: #000000;
mix-blend-mode: normal;
opacity: 0.5;
cursor: pointer;
}

.CardButton button:hover{
    color: #D87D4A;
    opacity: 1;
}

.ProductDetail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    
}

.ProductsImg img{
    width: 64px;
    height: 64px;
    border-radius: 10px;
    
}


.ProductsDetail .ProductName{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
}

.ProductsDetail .ProductPrice{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    mix-blend-mode: normal;
     opacity: 0.5;
}

.ProductQuantity{
    display: flex;

}
.ProductQuantity .Quantity{
    background-color: #F1F1F1;
    display: flex;
    justify-content: space-around;
    width: 96px;
    height: 35px;
   align-items: center;
    font-size: 14px;

}
.ProductQuantity .Quantity p:first-child,
.ProductQuantity .Quantity p:last-child {
    cursor: pointer;
    opacity: 0.5;
    font-size: 18px;
}
.ProductQuantity .Quantity p:first-child:hover,
.ProductQuantity .Quantity p:last-child:hover {
    color: #D87D4A;
    opacity: 1;
}


.ProductQuantity .Quantity .Number{
    font-weight: 700;
    color: black ;
    opacity: 1;
}

.ProductTotal{
    display: flex;
    justify-content: space-between;
    padding: 12px 0 24px 0;
}

.ProductTotal .Total{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    mix-blend-mode: normal;
    opacity: 0.5;
}

.ProductTotal .TotalPrice{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px; 
    /* text-align: center; */
}

.ProductCheckout button{
width: 100%;
height: 48px;
background-color: #D87D4A;
border: none;
cursor: pointer;


}

.ProductCheckout button a{
    color: white;
    text-decoration: none;
    font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 18px;
text-align: center;
letter-spacing: 1px;
}

.ProductCheckout button:hover {
    background-color: #FBAF85;
}


@media screen and (max-width: 768px){
    .Cardwindow{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .CartContents{
        max-width:330px;
        width: 95%;
        left: auto;
        right: auto;
        align-items: center;
        overflow: hidden;
        
    }
}