/* Product Card */
.DetailPageCard{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.GoBack{
    padding: 79px 0 56px 0; 
    
}
.GoBack a{
 text-decoration: none;
 color: black;
 opacity : 0.5;
}

.GoBack a:hover{
    color: #D87D4A;
    opacity: 1;
   }
.DetailPageCards{
    max-width: 1100px;
    display: flex;
    padding-bottom: 160px;
}
.DetailCard-Left{
    flex: 1;
    width: 50%;
}
.DetailCard-Right{
    padding: 110px 0;
    flex: 1;
    color: #000000;
}
.DetailPageCards .DetailCard-Left img{
   width: 100%;
    object-fit: contain;
    border-radius: 10px;
}
.DetailRight-Content{
    margin-left: 125px;
}
.DetailRight-Content h5{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: #D87D4A;
    padding-bottom: 16px;
}
.DetailRight-Content h1{
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 1.42px;
    text-transform: uppercase;
    padding-bottom: 32px;
}
.DetailRight-Content p{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    mix-blend-mode: normal;
     opacity: 0.5;
     padding-bottom: 32px;
}
.DetailRight-Content h3{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1.28571px;
    padding-bottom: 47px;
}
.AddCard{
    display: flex;
}
.AddCard .Quantity{
    background-color: #F1F1F1;
    display: flex;
    justify-content: space-around;
    width: 120px;
    height: 48px;
    padding: 10px 5px 0 5px ;
    font-size: 13px;
    margin-right: 16px;
}
.AddCard .Quantity p:first-child,
.AddCard .Quantity p:last-child {
    cursor: pointer;
}
.AddCard .Quantity .Number{
    font-weight: 700;
    color: black ;
    opacity: 1;
}
.DetailRight-Content button{
    width: 160px;
    height: 48px;
    background-color: #D87D4A;
    color: white;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.DetailRight-Content button:hover{
    background-color: #FBAF85;
}



/* Description */
.Features{
    max-width: 1100px;
    display: flex;
    padding-bottom: 160px;
    gap: 140px;
    padding-right: 100px;
}
.FeaturesLeft{
    flex: 2;
}
.FeaturesLeft h1,
.FeaturesRight h1{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1.15px;
    padding-bottom: 32px;
}
.FeaturesLeft p{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    mix-blend-mode: normal;
    opacity: 0.5;
}
.FeaturesRight .Includes{
display: flex;
flex-direction: column;
gap: 24px;
padding-bottom: 8px;
flex: 1;
}
.Include{
    gap: 24px;
}
.FeaturesRight .Includes .Include{
    display: flex;
}
.FeaturesRight .Includes .IncludesQuantity{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    color: #D87D4A;
}
.FeaturesRight .Includes .IncludesItem{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    mix-blend-mode: normal;
    opacity: 0.5;   
}



/* Gallery */
.DetailPageGallery{
    max-width: 1440px;
    margin-bottom: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PageGallery{
    max-width: 1100px;
    width: 80%;
    display: flex;
    justify-content: center;
}
.GalleryLeft {
    width: 50%;
    margin-right: 30px;
}
.GalleryLeft img{
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 25px;
}
.GalleryRight img{
    border-radius: 10px;
   object-fit: contain;
    
}



@media screen and (max-width: 1100px){



/* Product Card */
    .DetailContent{
     width: 90%;
    }
    .DetailPageCards{
        display: flex;
        padding-top: 0;
        width: 100%;
    }
    .DetailCard-Right{
        text-align: center;
        padding:0;
        padding-top: 50px;
    }
    .DetailPageCards .DetailCard-Left{
        max-width: 320px;
    }
    .DetailRight-Content{
        margin-left: 50px;
        text-align: left;
    }
    .DetailRight-Content h1{
       margin: 0 150px 20px 150px;
        margin: 0;
    }
    .DetailRight-Content h1{
        font-size: 28px;
    }
    .DetailRight-Content p{
        padding-bottom: 24px;
    }



    /* Description */
    .Features{
        width: 90%;
        flex-direction: column;
        padding-right: 0;
        padding-bottom: 120px;
    }
    .FeaturesRight{
        display: flex;
    }
    .Includes{
        flex-direction: column;
    }
    .BoxTitle{
         padding-right: 170px;
    }
    .items{
        display: flex;
    }



    /* Gallery */
    .PageGallery{
        width: 90%;
    }
    .GalleryRight{
        flex: 3;
    }
     .GalleryLeft{
        margin-right: 20px;
        flex: 2;
    }
     .GalleryRight img{
        width: 98%;
        object-fit: contain;
    }
     .GalleryLeft img{
        width: 100%;
        object-fit: contain;
    }
}


@media screen and (max-width: 699px){


    /* Product Card */
    .DetailPageCards{
        flex-direction: column;
    }
    .DetailRight-Content{
        margin: 0;
    }
    .DetailRight-Content h1{
        margin: 0;
        padding-bottom: 24px;
    }



    /* Description */
    .FeaturesRight{
        display: flex;
        flex-direction: column;
    }
    .DetailCard-Left{
        width: 100%;
    }
    .DetailPageCards .DetailCard-Left {
        max-width: 100%;
      }
    .BoxTitle{
        padding-right: 0;
       }


       /* Gallery */
       .DetailPageGallery{
           flex-direction: column;
       }
       .PageGallery{
           flex-direction: column;
       }
       .GalleryLeft{
           width: 98%;
        
       }


}